<template>
	<div class="container eLinks">

		<div class="hl"><h2>Links</h2></div>

		<div class="lRow">
			<div class="lColumn"><span class="hl">Agnes Fedl</span> <br>Gartenarchitektur und Landschaftsarchitektur</div>
			<div class="lColumn"><a href="http://www.fedl.eu/" target="_blank">www.fedl.eu</a></div>
		</div>

		<div class="lRow">
			<div class="lColumn"><span class="hl">Firmenverzeichnis Österreich</span><br><span class="sm">finden und gefunden werden</span></div>
			<div class="lColumn"><a href="https://www.firma.at/" target="_blank">www.firma.at</a></div>
		</div>
		<div class="lRow">
			<div class="lColumn"><span class="hl">Werkstatt Murberg</span><br><span class="sm">Verein zur Förderung künstlerischer und kultureller Interaktionen</span></div>
			<div class="lColumn"><a href="https://www.werkstattmurberg.com/" target="_blank">www.werkstattmurberg.at</a></div>
		</div>
		<div class="lRow">
			<div class="lColumn"><span class="hl">Hausmannstättner Keramik</span></div>
			<div class="lColumn"><a href="https://www.keramikbedarf.net"  target="_blank">www.keramikbedarf.net</a></div>
		</div>
		<div class="lRow">
			<div class="lColumn"><span class="hl">Rolf Laven</span></div>
			<div class="lColumn"><a href="http://www.rolflaven.com" target="_blank">www.rolflaven.com</a></div>
		</div>
		<div class="lRow">
			<div class="lColumn"><span class="hl">Kleine Zeitung Empfehlungsportal</span></div>
			<div class="lColumn"><a href="https://www.gutgemacht.at"  target="_blank">www.gutgemacht.at</a></div>
		</div>
		<div class="lRow">
			<div class="lColumn"><span class="hl">Urania</span> Bildungseinrichtung</div>
			<div class="lColumn"><a href="http://www.urania.at" target="_balnk">www.urania.at</a></div>
		</div>
		<div class="lRow">
			<div class="lColumn"><span class="hl">Kurt Halbritter</span> Karikaturen</div>
			<div class="lColumn"><a href="http://www.kurt-halbritter.com" target="_blank">www.kurt-halbritter.com</a></div>
		</div>
		<div class="lRow">
			<div class="lColumn"><span class="hl">Fast Pichl</span><br><span class="sm">Nachhaltiges Wissen für Wald und Natur</span></div>
			<div class="lColumn"><a href="http://www.fastpichl.at" target="_blank">www.fastpichl.at</a></div>
		</div>
	
		<div class="hl"><h2></h2></div>
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'Links',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">




.eLinks {
	text-align: left;


	
	.hl {
		text-align: center;
		// border-bottom: 1px solid #ddd;
		padding-bottom: 0.7rem;
		margin-bottom: 0.7rem;
		margin-top: 1rem;


	}
	a {

		text-decoration: none;
		color: var(--c13);

		&:hover {
			color: var(--c12);

		}
	}

	& .lRow {
		display: flex;
		flex-wrap: wrap;
		// background: #efefef;
		border-bottom: 1px solid #ddd;
		margin: 2px 0;
		min-height: 3rem;
	}

	& .lColumn {
		flex: 1 0 44%;
		padding: .2rem 1rem;
		align-self: center;


	}

	& .lColumn:nth-child(1){
		font-family: f2;
		& span.sm {
			font-size: small;
		}
		& span.hl {
			font-weight: bold;
		}

	}
	& .lColumn:nth-child(2){
		// font-weight: bold;
		font-size: 1.1rem;

	}
}


</style>
